import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import project1 from "../images/charpia_screenshot.png";
import project2 from "../images/simplesportsdata2.png";
import project3 from "../images/rnxchange.png";
import project4 from "../images/appimage1.jpg";
import project5 from "../images/appimage2.jpg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "3rem auto",
  },
}));

const projects = [
  {
    name: "Sports Data API",
    description: `Live Sports Data API engineered with Python, Flask and MongoDB and hosted with Amazon Web Services. At its peak, we served 40,000+ requests per hour to 100+ clients. Engineered from scratch to integrate, normalize and serve real-time data to customers.`,
    image: project2,
  },
  {
    name: "Law Firm Web Design and SEO",
    description: `Law firm website developed with Python / Django. SEO and speed optimization allowed the company to reposition their focus to Real Estate Law and reach #1 position for those keywords on Google within 6 months`,
    image: project1,
  },
  {
    name: "Recruitment Web App for Nurses/Hospitals",
    description: `Recruitment web app for Nurses/Hospitals built with Django, PostgreSQL and AngularJS. I worked with their team during pre-launch to add features, troubleshoot and optimize existing apps`,
    image: project3,
  },
  {
    name: "Auto Delivery App | iOS, Android and Web App",
    description: `Mobile App developed natively on iOS, Android and Web. The app functioned like Uber and enabled dealerships to coordinate stock deliveries.`,
    image: project4,
  },
  {
    name: "Background Check Service | API Integration",
    description: `Web App developed with Python, Django and Javascript. Service which integrated with APIs to process background checks and payments.`,
    image: project5,
  },
];

const Portfolio = () => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.mainContainer}>
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={4} key={i}>
            <Card className={classes.cardContainer}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Project 1"
                  height="140"
                  image={project.image}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {project.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Portfolio;
