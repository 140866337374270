import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GitHubIcon from "@material-ui/icons/GitHub";
import CodeIcon from "@material-ui/icons/Code";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  bottomNavContainer: {
    background: "#222",
  },
  root: {
    "& .MuiSvgIcon-root": {
      fill: "tan",
      "&:hover": {
        fill: "tomato",
        fontSize: "1.8rem",
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  const [value, setValue] = "";
  const handleChange = (event, newValue) => {

    if (newValue === "github") {
      window.location.href = 'https://github.com/dmhdev';
    } else if (newValue === "stackoverflow") {
      window.location.href = 'https://stackoverflow.com/users/1864151/mhdev';
    } else if (newValue === "linkedin") {
      window.location.href = 'https://www.linkedin.com/in/dayne-hammes-36b58783/';
    }

    return null;
    };

  return (
    <BottomNavigation className={classes.bottomNavContainer} value={value} onChange={handleChange} >
      <BottomNavigationAction value="github" icon={<GitHubIcon />} className={classes.root} />
      <BottomNavigationAction value="stackoverflow" icon={<CodeIcon />} className={classes.root} />
      <BottomNavigationAction value="linkedin" icon={<LinkedInIcon />} className={classes.root} />
    </BottomNavigation>
  );
};
export default Footer;
